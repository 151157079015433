let body = document.querySelector('body')
let burger = document.querySelector('.js-burger')
let menu = document.querySelector('.js-menu')
let headerOverlay = document.querySelector('.js-header__overlay')

burger & burger.addEventListener('click', () => {
  burger.classList.toggle('is-active')
  body.classList.toggle('no-scroll')
  headerOverlay.classList.toggle('is-active')
  menu.classList.toggle('is-active')
})

headerOverlay & headerOverlay.addEventListener('click', function(e) {
  if(e.target.className === headerOverlay.className) {
    body.classList.remove('no-scroll')
    burger.classList.remove('is-active')
    headerOverlay.classList.remove('is-active')
    menu.classList.remove('is-active')
  }
})